import React from "react";
import PropTypes from "prop-types";
import csvDownload from "json-to-csv-export";
import { Button } from "antd";
import dayjs from "dayjs";

const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    let propName = parent ? `${parent}.${key}` : key;

    // Check if value is a valid date string
    if (
      typeof obj[key] === "string" &&
      dayjs(obj[key]).isValid() &&
      dayjs(obj[key]).toISOString() === obj[key]
    ) {
      res[propName] = dayjs(obj[key]).format("YYYY-MM-DD"); // Format the date string
    }
    // // Check if value is a boolean
    // else if (typeof obj[key] === "boolean") {
    //   res[propName] = obj[key] ? "YES" : "NO"; // Convert boolean to 'yes'/'no'
    // }
    // Check if the value is an object and not an array
    else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res);
    }
    // For other types, just copy the value
    else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const ExportCsvButton = (props) => {
  const { data, fileName } = props;
  const prepareData = () => {
    const result = data.map((item) => {
      return flattenObject(item);
    });

    csvDownload({ data: result, filename: fileName });
  };

  return data?.length > 0 ? (
    <Button type="default" onClick={prepareData}>
      Download CSV
    </Button>
  ) : (
    <></>
  );
};

ExportCsvButton.propTypes = {
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportCsvButton;
