import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { List, Tag } from "antd";
import { apiGetStrategyActivity } from "api/apiStrategies";
import dayjs from "dayjs";
import { returnUpDownClassname, toFixed } from "utils/helpers";
import { ActivityTypes, ActivityProperties } from "./ActivityTypes";

const ActivityTime = (props) => {
  const { time } = props;
  return (
    <Tag className="w-[120px]">{dayjs(time).format("DD/MM/YY HH:mm:ss")}</Tag>
  );
};

const Description = (props) => {
  const { label, children } = props;
  return (
    <span className="ps-1">
      <strong>{label}: </strong>
      <span className="text-[#ffffff]">{children}</span>
    </span>
  );
};

const StrategyActivityCard = (props) => {
  const { selectedStrategy } = props;
  const [strategyActivity, setStrategyActivity] = useState([]);

  const fetchStrategyActivity = async () => {
    const response = await apiGetStrategyActivity(selectedStrategy._id);
    setStrategyActivity(response);
  };

  const returnContent = (activity) => {
    switch (activity.type) {
      case ActivityTypes.trendOpenNotify: {
        return (
          <div>
            <Description label="Buy point">
              {toFixed(activity.data.deal.advicedBuyPrice)}
            </Description>
            {" |"}
            <Description label="Contracts">
              {toFixed(activity.data.deal.advicedBuyAmount)}
            </Description>
            {/* on{" "}
          {dayjs(
            new Date(activity.data.deal.advicedBuyDate).toDateString()
          )
            .add(+23, "h")
            .format("DD/MM/YY HH:mm")} */}
          </div>
        );
      }
      case ActivityTypes.tradeOpen: {
        return (
          <div>
            <Description label="Open rate">
              {toFixed(activity.data.deal.openRate)}
            </Description>
            {" |"}
            <Description label="Contracts">
              {toFixed(activity.data.deal.amount)}
            </Description>
            {/* on{" "}
          {dayjs(activity.data.deal.openDate).format("DD/MM/YY HH:mm")} */}
          </div>
        );
      }
      case ActivityTypes.tradeClose: {
        return (
          <div>
            <Description label="PNL">
              <span
                className={returnUpDownClassname(
                  activity.data.deal.profit.value
                )}
              >
                {toFixed(activity.data.deal.profit.perc)}% $
                {toFixed(activity.data.deal.profit.value)}
              </span>
            </Description>
            {" |"}
            <Description label="Close rate">
              {toFixed(activity.data.deal.closeRate)}
            </Description>
            {" |"}
            <Description label="Contracts">
              {toFixed(activity.data.deal.amount)}
            </Description>
            {/* on {dayjs(activity.data.deal.closeDate).format("DD/MM/YY")} */}
          </div>
        );
      }
      case ActivityTypes.deletePendingBuy: {
        return (
          <div>
            <Description label="Buy point">
              {toFixed(activity.data.deal.advicedBuyPrice)}
            </Description>
            {" |"}
            <Description label="Contracts">
              {toFixed(activity.data.deal.advicedBuyAmount)}
            </Description>
          </div>
        );
      }
      case ActivityTypes.missedSellOpportunity: {
        return (
          <div>
            <Description label="Rate">
              {toFixed(activity.data.dealSnapshot.currentRate)} |
            </Description>
            {" |"}
            <Description label="Sell point">
              {toFixed(activity.data.dealSnapshot.advicedSellPrice)} |
            </Description>
            {" |"}
            <Description label="Contracts">
              {toFixed(activity.data.dealSnapshot.amount)}
            </Description>
          </div>
        );
      }
      case ActivityTypes.tradeCloseNotify: {
        return (
          <div>
            <Description label="Sell point">
              {toFixed(activity.data.dealSnapshot.advicedSellPrice)} |
            </Description>
            {" |"}
            <Description label="Contracts">
              {toFixed(activity.data.dealSnapshot.amount)}
            </Description>
          </div>
        );
      }
      case ActivityTypes.tradeUpdate: {
        return (
          <div>
            <Description label="PNL">
              <span
                className={returnUpDownClassname(
                  activity.data.dealSnapshot.profit.value
                )}
              >
                {toFixed(activity.data.dealSnapshot.profit.perc)}% $
                {toFixed(activity.data.dealSnapshot.profit.value)}
              </span>
            </Description>
            {" |"}
            <Description label="Rate">
              {toFixed(activity.data.dealSnapshot.currentRate)}
            </Description>
          </div>
        );
      }
      case ActivityTypes.automatedTradeOpenFail: {
        return <div>Failed to open trade with automation</div>;
      }
      case ActivityTypes.automatedTradeCloseFail: {
        return <div>Failed to close trade with automation</div>;
      }
      default: {
        return <div />;
      }
    }
  };

  useEffect(() => {
    fetchStrategyActivity();
  }, []);

  return (
    <GenericCard
      title="Strategy Activity"
      className="table-card"
      maxHeight={800}
    >
      <List
        pagination={{
          position: "bottom",
          align: "center",
          defaultPageSize: 8,
          showSizeChanger: true,
        }}
        size="small"
        dataSource={strategyActivity}
        renderItem={(item) => (
          <List.Item extra={<ActivityTime time={item.created} />}>
            <List.Item.Meta
              title={
                <Tag
                  color={ActivityProperties[item.type].color}
                  icon={ActivityProperties[item.type].icon}
                >
                  {ActivityProperties[item.type].title}
                </Tag>
              }
              description={returnContent(item)}
            />
          </List.Item>
        )}
      />
    </GenericCard>
  );
};

StrategyActivityCard.propTypes = {
  selectedStrategy: PropTypes.object.isRequired,
};

export default StrategyActivityCard;
