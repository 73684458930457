import React from "react";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const ActivityTypes = {
  trendChanged: "trend-changed",
  trendOpenNotify: "trade-open-notify",
  tradeOpen: "trade-open",
  tradeClose: "trade-close",
  deletePendingBuy: "delete-pending-buy",
  missedSellOpportunity: "missed-sell-opportunity",
  tradeCloseNotify: "trade-close-notify",
  tradeUpdate: "trade-update",
  automatedTradeOpenFail: "automated-trade-open-failed",
  automatedTradeCloseFail: "automated-trade-close-failed",
};

export const ActivityProperties = {
  [ActivityTypes.trendChanged]: { title: "Trend changed", color: "" },
  [ActivityTypes.trendOpenNotify]: {
    title: "Trade Open Signal",
    color: "lime",
    icon: <ExclamationCircleOutlined />,
  },
  [ActivityTypes.tradeOpen]: {
    title: "Trade Opened",
    color: "green",
    icon: <CheckCircleOutlined />,
  },
  [ActivityTypes.tradeClose]: {
    title: "Trade Closed",
    color: "volcano",
    icon: <CheckCircleOutlined />,
  },
  [ActivityTypes.deletePendingBuy]: {
    title: "Delete Pending Trade",
    color: "red",
    icon: <MinusCircleOutlined />,
  },
  [ActivityTypes.missedSellOpportunity]: {
    title: "Missed Trade Close",
    color: "magenta",
    icon: <ClockCircleOutlined />,
  },
  [ActivityTypes.tradeCloseNotify]: {
    title: "Trade Close Signal",
    color: "gold",
    icon: <ExclamationCircleOutlined />,
  },
  [ActivityTypes.tradeUpdate]: {
    title: "Trade Updated",
    color: "geekblue",
    icon: <SyncOutlined />,
  },
  [ActivityTypes.automatedTradeOpenFail]: {
    title: "Automated Trade Open Failed",
    color: "red",
    icon: <CloseCircleOutlined />,
  },
  [ActivityTypes.automatedTradeCloseFail]: {
    title: "Automated Trade Close Failed",
    color: "error",
    icon: <CloseCircleOutlined />,
  },
};
