import { Button, Divider } from "antd";
import { apiRefreshPlatformForAllUsers } from "api/apiUtils";
import GenericCard from "components/UI/GenericCard/GenericCard";
import ConnectionsTable from "components/Cards/ConnectionsTable";
import ProtectedContent from "components/UI/ProtectedContent";
import React, { useEffect } from "react";

const SystemControl = () => {
  useEffect(() => {
    document.title = "System Control | Dindicator Dashboard";
  }, []);

  const onRefreshPlatformForAllUsers = async () => {
    try {
      await apiRefreshPlatformForAllUsers();
    } catch (err) {}
  };

  return (
    <>
      <Divider orientation="left">System control</Divider>
      <div className="cards-wrapper">
        <ProtectedContent>
          <GenericCard title="General tools">
            <div className="flex flex-wrap gap-3">
              <Button onClick={onRefreshPlatformForAllUsers}>
                Force refersh dashboard
              </Button>
            </div>
          </GenericCard>
        </ProtectedContent>
        <ConnectionsTable />
      </div>
    </>
  );
};

export default SystemControl;
