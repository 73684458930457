import React, { useCallback } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import GenericCard from "components/UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";

import StrategyOpenOrders from "./Cards/StrategyOpenOrders";
import { returnUpDownClassname } from "utils/helpers";

const StrategiesTable = (props) => {
  const { strategies, onLoadStrategy, onUnloadStrategy, selectedIds } = props;
  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Load",
      key: "uid",
      dataIndex: "",
      render: (text, propeties) =>
        selectedIds[propeties._id] ? (
          <Button onClick={() => onUnloadStrategy(text._id)}>Hide</Button>
        ) : (
          <Button onClick={() => onLoadStrategy(propeties)}>Load</Button>
        ),
    },
    // {
    //   title: "Enabled",
    //   dataIndex: "enabled",
    //   render: (text) => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       {text ? (
    //         <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
    //       ) : (
    //         <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
    //       )}
    //     </div>
    //   ),
    //   sorter: {
    //     compare: (a, b) => sortByNumber(a.enabled, b.enabled),
    //   },
    //   // width: 90,
    // },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   sorter: {
    //     compare: (a, b) => sortByText(a.name, b.name),
    //   },
    // },
    {
      title: "Symbol",
      dataIndex: "symbol",
      render: (text) => <div className="min-w-[50px]">{text}</div>,
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      render: (text, properties) => (
        <div className="max-w-[200px]">{properties.model.name}</div>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.model.name, b.model.name),
      },
    },
    {
      title: "Buy predicts",
      dataIndex: "buyPredictionsInRow",
      render: (text, properties) => (
        <div className="min-w-[50px]">
          {text} / {properties.params.enterPositionBuyCount}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.buyPredictionsInRow, b.buyPredictionsInRow),
      },
    },
    {
      title: "No Buy predicts",
      dataIndex: "noBuyPredictionsInRow",
      render: (text, properties) => (
        <div className="min-w-[50px]">
          {text} / {properties.params.exitPositionNoBuyCount}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.noBuyPredictionsInRow, b.noBuyPredictionsInRow),
      },
    },
    {
      title: "Days Exit",
      dataIndex: "params.daysOpenExitCount",
      render: (text, properties) => (
        <div className="min-w-[50px]">
          {properties.params.daysOpenExitCount}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.params.daysOpenExitCount, b.params.daysOpenExitCount),
      },
    },
    // {
    //   title: "TP",
    //   dataIndex: "params.takeProfitPercent",
    //   render: (text, properties) => (
    //     <span>{properties.params.takeProfitPercent}%</span>
    //   ),
    //   sorter: {
    //     compare: (a, b) =>
    //       sortByNumber(a.params.takeProfitPercent, b.params.takeProfitPercent),
    //   },
    // },
    // {
    //   title: "SL",
    //   dataIndex: "params.stopLossPercent",
    //   render: (text, properties) => (
    //     <span>{properties.params.stopLossPercent}%</span>
    //   ),
    //   sorter: {
    //     compare: (a, b) =>
    //       sortByNumber(a.params.stopLossPercent, b.params.stopLossPercent),
    //   },
    // },
    {
      title: "Equity",
      // width: 100,
      dataIndex: "equity",
      render: (text) => <span>${text.toLocaleString()}</span>,
      sorter: {
        compare: (a, b) => sortByNumber(a.equity, b.equity),
      },
    },
    {
      title: "PNL",
      dataIndex: "metrics.pnl",
      render: (text, properties) => (
        <div className={returnUpDownClassname(properties.metrics.pnl)}>
          <div>${properties.metrics.pnl.toLocaleString()}</div>
          <span style={{ fontSize: 12 }}>%{properties.metrics.pnlPercent}</span>
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.metrics.pnl, b.metrics.pnl),
      },
    },
    {
      title: "Average PNL",
      dataIndex: "metrics.averageProfit",
      render: (text, properties) => (
        <span>{properties.metrics.averageProfit}%</span>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.metrics.averageProfit, b.metrics.averageProfit),
      },
    },
    {
      title: "Success",
      dataIndex: "metrics.successRate",
      render: (text, properties) => (
        <div className="min-w-[60px]">
          <div>
            {properties.metrics.closedWithProfit} /{" "}
            {properties.metrics.totalTransactions}
          </div>
          <div>{properties.metrics.successRate}%</div>
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.metrics.successRate, b.metrics.successRate),
      },
    },

    // {
    //   title: "Average hold",
    //   dataIndex: "metrics.averageDuration",
    //   render: (text, properties) => (
    //     <span>{properties.metrics.averageDuration} days</span>
    //   ),
    //   sorter: {
    //     compare: (a, b) =>
    //       sortByNumber(a.metrics.averageDuration, b.metrics.averageDuration),
    //   },
    // },
    {
      title: "Pending Buy",
      dataIndex: "pendingBuys",
      render: (text) => (
        <div className="flex items-center min-w-[50px]">
          {text.length > 0 ? (
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.pendingBuys.length, b.pendingBuys.length),
      },
    },
    {
      title: "Active Position",
      dataIndex: "openPositions",
      render: (text) => (
        <div className="flex items-center min-w-[50px]">
          {text.length > 0 ? (
            // <Badge count={text.length} size="small">
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            // </Badge>
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.openPositions.length, b.openPositions.length),
      },
    },
    {
      title: "Pending Sell",
      dataIndex: "pendingSells",
      render: (text) => (
        <div className="flex items-center min-w-[50px]">
          {text.length > 0 ? (
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.pendingSells.length, b.pendingSells.length),
      },
    },
    {
      title: "Automated",
      dataIndex: "automated",
      render: (text) => (
        <div className="flex items-center min-w-[50px]">
          {text ? (
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.automated, b.automated),
      },
    },
    {
      title: "Created",
      // width: 90,
      dataIndex: "created",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.created, b.created),
      },
    },
    {
      title: "Last update",
      // width: 90,
      dataIndex: "lastUpdate",
      render: (text) => (
        <>{text ? dayjs(text).format("DD/MM/YY") : "Pending"}</>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.lastUpdate, b.lastUpdate),
      },
    },
  ];

  return (
    <div className="cards-wrapper">
      <GenericCard
        title={`Strategies (${strategies.length})`}
        className="table-card"
        maxHeight={900}
      >
        <AntTable
          rowKey="_id"
          dataSource={strategies}
          columns={columns}
          rowClassName={(obj) =>
            selectedIds[obj._id] ? "table-row-selected" : ""
          }
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <h4>Strategy Name:</h4>
                <p>{record.name}</p>
                {record.openPositions.length > 0 && (
                  <>
                    <h4 style={{ paddingBlock: 10 }}>Open Orders</h4>
                    <StrategyOpenOrders
                      openOrders={record.openPositions}
                      profitTargetPercent={record.params.takeProfitPercent}
                    />
                  </>
                )}
              </div>
            ),
            rowExpandable: (record) => true,
          }}
        />
      </GenericCard>
    </div>
  );
};

StrategiesTable.propTypes = {
  strategies: PropTypes.array,
  onLoadStrategy: PropTypes.func,
  onUnloadStrategy: PropTypes.func,
  selectedIds: PropTypes.object,
};

export default StrategiesTable;
