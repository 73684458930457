import axios from "utils/axios";
import { routes } from "api/routes";

export const apiGetCandles = async (params) => {
  const { data } = await axios.get(routes.getCandles, {
    params,
  });
  return data;
};

export const apiRefreshPlatformForAllUsers = async (userId) => {
  const { data } = await axios.get(routes.refreshPlatformForAllUsers, {
    params: { userId },
  });
  return data;
};

export const apiCheckYFinanceDifference = async (formData) => {
  const { data } = await axios.get(routes.checkYFinanceDifference, {
    params: formData,
  });
  return data;
};
