import React, { useCallback, useEffect, useMemo } from "react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { Button, Table, Tooltip } from "antd";
import ExportCsvButton from "components/UI/ExportCsvButton";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import ModelDetails from "./ModelDetails";
import getRangeFilterProps from "utils/tableRangeFilter";

const ResultsHistory = ({
  history,
  onLoadAiResult,
  setResultsHistory,
  onRemoveAiResult,
  selectedIds,
  shownPredictionResults,
  setShownPredictionResults,
  isShared,
}) => {
  const filters = useMemo(() => {
    const initialState = { modelName: {}, symbols: {} };
    if (history) {
      const allFilters = history?.reduce((acc, item) => {
        acc.modelName[item.model._id] = {
          text: item.model.name,
          value: item.model.name,
        };

        acc.symbols[item.symbol] = {
          text: item.symbol,
          value: item.symbol,
        };
        return acc;
      }, initialState);

      allFilters.modelName = Object.values(allFilters.modelName);
      allFilters.symbols = Object.values(allFilters.symbols);
      return initialState;
    }
    return {};
  }, [history]);

  const onClearHistory = async () => {
    setResultsHistory([]);
  };

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Load",
      key: "uid",
      dataIndex: "",
      render: (text) =>
        selectedIds[text._id] ? (
          <Button onClick={() => onRemoveAiResult(text._id)}>Remove</Button>
        ) : (
          <Button onClick={() => onLoadAiResult(text)}>Load</Button>
        ),
    },
    {
      title: "",
      dataIndex: "model",
      width: 60,
      render: (text, obj) => <ModelDetails model={obj.model} />,
      sorter: {
        compare: (a, b) => sortByText(a.model.name, b.model.name),
        multiple: 1,
      },
      filters: filters.modelName,
      filterSearch: true,
      onFilter: (value, record) => record.model.name.startsWith(value),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
        multiple: 2,
      },
      filters: filters.symbols,
      filterSearch: true,
      onFilter: (value, record) => record.symbol.startsWith(value),
    },
    {
      title: "Price",
      dataIndex: "lastClosePrice",
      render: (text, obj) => (
        <Tooltip
          title={`Model ${obj.modelPercGainTarget}% target: $${obj.modelPriceTarget}`}
        >
          {text}
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.lastClosePrice, b.lastClosePrice),
      },
    },
    {
      title: "Prediction",
      dataIndex: "prediction",
      render: (text) => (
        <span className={text === "buy" ? "green" : "red"}>{text}</span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.prediction, b.prediction),
        multiple: 2,
      },
      filterMultiple: false,
      filters: [
        {
          text: "Buy",
          value: "buy",
        },
        {
          text: "No buy",
          value: "no buy",
        },
      ],
      onFilter: (value, record) => record.prediction.startsWith(value),
    },
    // {
    //   title: "Buy/Total",
    //   dataIndex: "buyFromTotal",
    //   render: (text) => <>{text.toFixed(2)}%</>,
    //   sorter: {
    //     compare: (a, b) => a.buyFromTotal - b.buyFromTotal,
    //     multiple: 1,
    //   },
    //   ...getRangeFilterProps("buyFromTotal"),
    // },
    {
      title: "Actual Buy/Total",
      dataIndex: "actualBuyFromTotal",
      render: (text) => <>{text.toFixed(2)}%</>,
      sorter: {
        compare: (a, b) => a.actualBuyFromTotal - b.actualBuyFromTotal,
        multiple: 1,
      },
      ...getRangeFilterProps("actualBuyFromTotal"),
    },
    {
      title: "TP",
      dataIndex: "tp",
      render: (text) => <>{text.toFixed(2)}%</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.tp, b.tp),
        multiple: 2,
      },
      ...getRangeFilterProps("tp"),
    },
    {
      title: "Buy",
      dataIndex: "buyCount",
      render: (totalBuy, obj) => (
        <>
          {obj.successBuy} / {totalBuy}
        </>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.successBuy, b.successBuy),
        multiple: 2,
      },
      ...getRangeFilterProps("successBuy"),
    },
    {
      title: "TN",
      dataIndex: "tn",
      render: (text) => <>{text.toFixed(2)}%</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.tn, b.tn),
        multiple: 1,
      },
      ...getRangeFilterProps("tn"),
    },
    {
      title: "No Buy",
      dataIndex: "noBuyCount",
      render: (totalNoBuy, obj) => (
        <>
          {obj.successNoBuy} / {totalNoBuy}
        </>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.successNoBuy, b.successNoBuy),
        multiple: 1,
      },
      ...getRangeFilterProps("successNoBuy"),
    },
    {
      title: "Predictions",
      dataIndex: "totalPredictions",
      sorter: {
        compare: (a, b) => sortByNumber(a.totalPredictions, b.totalPredictions),
        multiple: 1,
      },
      ...getRangeFilterProps("totalPredictions"),
    },
    {
      title: "From",
      dataIndex: "startDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.startDate, b.startDate),
        multiple: 2,
      },
    },
    {
      title: "To",
      dataIndex: "endDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.endDate, b.endDate),
      },
    },
    {
      title: "Trained symbol",
      dataIndex: "isModelTrainedOn",
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text ? (
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.isModelTrainedOn, b.isModelTrainedOn),
      },
      filterMultiple: false,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isModelTrainedOn === value,
    },
    {
      title: "Train Overlap",
      dataIndex: "isTrainedDataOverlap",
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text ? (
            <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) =>
          sortByNumber(a.isTrainedDataOverlap, b.isTrainedDataOverlap),
      },
      filterMultiple: false,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isTrainedDataOverlap === value,
    },
    {
      title: "Corrupted",
      dataIndex: "isCorrupted",
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text ? (
            <span style={{ fontSize: 22 }}>👮</span>
          ) : (
            <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
          )}
        </div>
      ),
      sorter: {
        compare: (a, b) => sortByNumber(a.isCorrupted, b.isCorrupted),
      },
      filterMultiple: false,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isCorrupted === value,
    },
  ];
  useEffect(() => {
    if (history) {
      setShownPredictionResults(history);
    }
  }, [history?.length]);

  return (
    <div className="cards-wrapper">
      <GenericCard
        title="Results History"
        className="table-card"
        maxHeight={650}
      >
        <div className="results-table-buttons">
          {!isShared && (
            <Button type="default" onClick={onClearHistory}>
              Clear history
            </Button>
          )}
          <ExportCsvButton
            data={shownPredictionResults}
            fileName="Predictions"
          />

          <p>Total results: {shownPredictionResults.length}</p>
        </div>

        <Table
          pagination={false}
          rowKey="_id"
          rowClassName={(obj) =>
            selectedIds[obj._id] ? "table-row-selected" : ""
          }
          dataSource={history}
          columns={columns}
          virtual
          scroll={{
            x: 2000,
            y: 400,
          }}
          sortOrder="descend"
          loading={!history}
          onChange={(pagination, filters, sorter, extra) => {
            setShownPredictionResults(extra.currentDataSource);
          }}
        />
      </GenericCard>
    </div>
  );
};

export default ResultsHistory;
