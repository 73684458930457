const apiBase = "/api";

const usersRoutes = {
  getUserDetails: `${apiBase}/users/me`,
  login: `${apiBase}/users/login`,
  logout: `${apiBase}/users/logout`,
  online: `${apiBase}/users/online`,
  changeDefaultPremiumFee: `${apiBase}/users/change-premium-fee`,
  changePassword: `${apiBase}/users/change-password`,
  getOrderTrackerToken: `${apiBase}/users/order-tracker-token`,
  unlinkTelegramAccount: `${apiBase}/users/telegram-account-unlink`,
  getUserInvitationByToken: `${apiBase}/users/invitation-details`,
  completeUserRegistration: `${apiBase}/users/complete-registration`,
};

const twsRoutes = {
  getTwsStatus: `${apiBase}/tws/tws_status`,
  startTws: `${apiBase}/tws/start_tws`,
  stopTws: `${apiBase}/tws/stop_tws`,
  getAutomationStatus: `${apiBase}/tws/automation-status`,
  startAutomation: `${apiBase}/tws/start-automation`,
  stopAutomation: `${apiBase}/tws/stop-automation`,
  getAutomationLogs: `${apiBase}/tws/get-logs`,
  updateLoginDetails: `${apiBase}/tws/update-login-details`,
  checkIfIBCConfigExists: `${apiBase}/tws/check-ibc-config`,
};

const utilsRoutes = {
  getCandles: `${apiBase}/utils/get_candles`,
  refreshPlatformForAllUsers: `${apiBase}/utils/refresh-platform`,
  checkYFinanceDifference: `${apiBase}/utils/check-yfinance-difference`,
};

const symbolsRoutes = {
  getAllSymbols: `${apiBase}/symbols`,
  addNewSymbol: `${apiBase}/symbols`,
  updateSymbolById: `${apiBase}/symbols`,
  deleteSymbolById: `${apiBase}/symbols`,
  enableSymbolById: `${apiBase}/symbols/enable-symbol`,
  disableSymbolById: `${apiBase}/symbols/disable-symbol`,
  pullDayCandlesFullHistory: `${apiBase}/symbols/pull-day_candles_history`,
};

const strategyTabsRoutes = {
  getAllStrategyTabs: `${apiBase}/strategy-tabs`,
  createNewStrategyTab: `${apiBase}/strategy-tabs`,
  updateStrategyTabById: `${apiBase}/strategy-tabs`,
  deleteStrategyTabById: `${apiBase}/strategy-tabs`,
};

const sectorsRoutes = {
  getAllSectors: `${apiBase}/sectors`,
};

const strategiesRoutes = {
  createNewStrategy: `${apiBase}/strategy/create`,
  toggleStrategyActive: `${apiBase}/strategy/toggle-active`,
  toggleStrategyAccountType: `${apiBase}/strategy/account-type`,
  checkSimilar: `${apiBase}/strategy/check-similar`,
  getUserStrategies: `${apiBase}/strategy/all`,
  recountStrategyTrend: `${apiBase}/strategy/recount-trend`,
  confirmStrategyTrade: `${apiBase}/strategy/confirm-strategy-trade`,
  confirmStrategyTradeSell: `${apiBase}/strategy/confirm-strategy-trade-sell`,
  deleteStrategy: `${apiBase}/strategy/delete-strategy`,
  updateStrategy: `${apiBase}/strategy/update-strategy`,
  createNewStrategyNote: `${apiBase}/strategy/create-note`,
  updateStrategyNote: `${apiBase}/strategy/update-note`,
  deleteStrategyNote: `${apiBase}/strategy/delete-note`,
  getStrategyById: `${apiBase}/strategy/details`,
};

const tradesRoutes = {
  getTradesHistory: `${apiBase}/trades/history`,
  triggerCloseTrade: `${apiBase}/trades/trigger-close-trade`,
};

const brokerOrderRoutes = {
  getBrokerOrders: `${apiBase}/broker-orders/executions`,
  retryToOpenTrade: `${apiBase}/broker-orders/retry-open-failed-trade`,
};

const aiModelRoutes = {
  createNewBacktest: `${apiBase}/ai-models`,
  getModelsList: `${apiBase}/ai-models`,
  deleteModelById: `${apiBase}/ai-models`,
  getModelById: `${apiBase}/ai-models/details`,
};

const aiResultsRoutes = {
  checkAiSymbol: `${apiBase}/ai-results/predict-job`,
};

const predictionsRoutes = {
  checkAiSymbolNew: `${apiBase}/predictions/predict-new`,
  getPredictions: `${apiBase}/predictions/get-predictions`,
  getPredictionsMultipleModels: `${apiBase}/predictions/get-predictions-multi-models`,
  searchBacktestStrategies: `${apiBase}/predictions/search-strategies-backtest`,
  getSymbolRecommendations: `${apiBase}/predictions/symbol-recommendations`,
  evaluateBuySellPolarity: `${apiBase}/predictions/evaluate-buy-sell-polarity`,
};

const adminRoutes = {
  getLogsByUser: `${apiBase}/admin/user-logs`,
  searchTrades: `${apiBase}/admin/search-trades`,
  editTrade: `${apiBase}/admin/edit-trade`,
  deleteTrade: `${apiBase}/admin/delete-trade`,
  inviteUser: `${apiBase}/admin/invite-user`,
  deleteUser: `${apiBase}/admin/delete-user`,
  toggleBanUser: `${apiBase}/admin/ban-user`,
  revokeToken: `${apiBase}/admin/revoke-user-token`,
  getOnlineUserActvity: `${apiBase}/admin/online-user-activity`,
  searchUser: `${apiBase}/admin/search-user`,
  getUserDetailsById: `${apiBase}/admin/user-details`,
  getConnectionToServer: `${apiBase}/admin/get-connections`,
};

const strategyActivityRoutes = {
  getStrategyActivity: `${apiBase}/strategy-activity`,
};

export const routes = {
  ...usersRoutes,
  ...twsRoutes,
  ...utilsRoutes,
  ...symbolsRoutes,
  ...sectorsRoutes,
  ...aiModelRoutes,
  ...aiResultsRoutes,
  ...adminRoutes,
  ...strategiesRoutes,
  ...tradesRoutes,
  ...strategyTabsRoutes,
  ...predictionsRoutes,
  ...strategyActivityRoutes,
  ...brokerOrderRoutes,
};
