import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
// import { useVT } from "virtualizedtableforantd4";

const AntTable = (props) => {
  // const [vt, set_components] = useVT(() => ({ scroll: { y: 500 } }), []);

  return (
    <Table
      scroll={{
        x: 2000,
        y: 400,
      }}
      virtual
      pagination={false}
      sortOrder="descend"
      size="middle"
      {...props}
    />
  );
};

AntTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  className: PropTypes.string,
  rowKey: PropTypes.string.isRequired,
};

export default AntTable;
